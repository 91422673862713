body {
  overflow: hidden;
  border: 0;
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100%;
  text-align: center;
  background-color: lightblue;
}
* {
  margin: 0;
  border: 0;
  padding: 0;
  font-family: "Merriweather", serif;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

h1 {
  font-size: 100px;
  font-weight: 900;
  margin: 10px;
  text-shadow: 1px 1px 5px rgb(193, 193, 193);
}
h2 {
  font-size: 46px;
  font-weight: 700;
  margin: 10px;
  text-shadow: 1px 1px 5px rgb(193, 193, 193);
}
h3 {
  font-size: 30px;
  font-weight: 300;
  margin: 45px;
  font-style: italic;
  text-shadow: 1px 1px 5px rgb(193, 193, 193);
}
p {
  font-size: 25px;
  font-weight: 300;
  margin: 50px;
  text-shadow: 1px 1px 5px rgb(193, 193, 193);
}
img {
  margin: 20px;
  height: 100px;
  width: 100px;
}
video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
